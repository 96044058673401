
import React from 'react'
import gowthaman from '../../assets/images/team/gowthaman.jpeg';
import  PieChart  from '../Menhome/PieChart.js';


function Why() {
    return (
        <>
            <section className="why-choose">
                <img src="assets/images/shapes/why-choose-shape-1-1.png" className="why-choose__shape-1" alt="" />
                <img src="assets/images/shapes/why-choose-shape-1-2.png" className="why-choose__shape-2" alt="" />
                <div className="container">
                    <div className="row">
                        {/* <div className="col-lg-4">
                            <div className="why-choose__image">
                                <p><i className="pylon-icon-investment"></i>26 years of working experience</p>
                                <img src={gowthaman} alt="" className='rounded-image' />
                            </div>
                        </div> */}
                        <div className="col-lg-7 whyChoose">
                            <div className="why-choose__content">
                                <div className="block-title text-left">
                                    <p>Our Benefits</p>
                                    <h2>Why Choose Us?</h2>
                                </div>
                                <p>We're not just about loans; we're about empowering you to make your vehicle ownership dreams come true. Trust us for a smooth and secure ride into the future!</p>

                            </div>

                        </div>
                        <div className="row col-lg-12 whyprogress">
                                        {/* <div className="why-choose__box col-lg-6">
                                            <h3><i className="fa fa-caret-right"></i>1.	Regulatory Compliance</h3>
                                            <div><p>Our company adheres to the highest standards of regulatory compliance, ensuring that your financial transactions are secure and transparent. With the backing of the Reserve Bank of India, you can trust us to provide reliable and legitimate financial solutions.</p></div>
                                        </div>
                                        <div className="why-choose__box col-lg-6">
                                            <h3><i className="fa fa-caret-right"></i>2.	 Customer experience</h3>
                                            <div><p>Our company adheres to the highest standards of regulatory compliance, ensuring that your financial transactions are secure and transparent. With the backing of the Reserve Bank of India, you can trust us to provide reliable and legitimate financial solutions.</p></div>
                                        </div>
                                        <div className="why-choose__box col-lg-6">
                                            <h3><i className="fa fa-caret-right"></i>3. Tailored Solutions</h3>
                                            <div><p>We recognize that every individual has unique financial requirements. Our range of vehicle loan products is designed to cater to diverse needs, offering flexible terms and competitive interest rates to suit your budget.</p></div>
                                        </div>
                                        <div className="why-choose__box col-lg-6">
                                            <h3><i className="fa fa-caret-right"></i>4.	Quick and Easy Approval</h3>
                                            <div><p>Time is of the essence, especially when it comes to securing your dream vehicle. Our streamlined approval process ensures that you receive a quick response, allowing you to hit the road without unnecessary delays.</p></div>
                                        </div>
                                        <div className="why-choose__box col-lg-6">
                                            <h3><i className="fa fa-caret-right"></i>5.	Customer-Centric Approach</h3>
                                            <div><p>Your satisfaction is our top priority. Our customer-centric approach means that we are always ready to listen to your concerns, answer your queries, and work towards finding solutions that meet your expectations.</p></div>
                                        </div> */}
                                               <PieChart/>  
                                        {/* <div className='col col-lg-6'>                                    */}
                                {/* <div className="why-choose__progress col-lg-12">
                                    <div className="why-choose__progress-top">
                                        <h3>Loan Process</h3>
                                        <span>90%</span>
                                    </div>
                                    <div className="why-choose__progress-bar">
                                        <span style={{ width: '90%' }} className="wow slideInLeft" data-wow-duration="1500ms"></span>
                                    </div>
                                </div> */}
                                {/* <div className="why-choose__progress col-lg-12">
                                    <div className="why-choose__progress-top">
                                        <h3>Consultancy</h3>
                                        <span>76%</span>
                                    </div>
                                    <div className="why-choose__progress-bar">
                                        <span style={{ width: '76%' }} className="wow slideInLeft" data-wow-duration="1500ms"></span>
                                    </div>
                                </div> */}
                             {/* </div> */}
                                </div>
             
                    </div>
                </div>
            </section>
        </>
    )
}

export default Why