import FunFactBG1 from '../../assets/images/backgrounds/funfact-bg-1-1.jpg'
import AnimatedNumber from "animated-number-react"
import React from 'react'


function Counter() {
  return (
    <>
        <section className="funfact-one pl-5">
            {/* <div className="funfact-one__bg" style={{ backgroundImage: `url(${FunFactBG1})`}}></div> */}
            <div className="container ">
                <div className="row">
                    <div className="col-md-6 col-lg-4">
                        <div className="funfact-one__box">
                            <h3><span className="odometer" data-count="99"><AnimatedNumber value={99} duration={6000} formatValue={(v) => Math.round(v)} /></span>%</h3>
                            <p>We Approve Loan</p>
                        </div>
                    </div>
                   {/* <div className="col-md-6 col-lg-3">
                        <div className="funfact-one__box">
                            <h3>$<span className="odometer" data-count="90"><AnimatedNumber value={90} duration={8000} formatValue={(v) => Math.round(v)} /></span>k</h3>
                            <p>Daily Payments</p>
                        </div>
                    </div> */}
                    <div className="col-md-6 col-lg-4">
                        <div className="funfact-one__box">
                            <h3><span className="odometer" data-count="3000"><AnimatedNumber value={3000} duration={6000} formatValue={(v) => Math.round(v)} /></span></h3>
                            <p>Happy Customers</p>
                        </div>
                    </div>
                   <div className="col-md-6 col-lg-4">
                        <div className="funfact-one__box">
                            <h3><span className="odometer" data-count="12"><AnimatedNumber value={12} duration={6000} formatValue={(v) => Math.round(v)} /></span></h3>
                            <p>Our Branches</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default Counter