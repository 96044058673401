import React from 'react';
import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css'; 
import styles from '../../assets/css/slider.css';
import gowthaman from '../../assets/images/team/gowthaman.jpeg'
import sridhar from '../../assets/images/team/sridhar1.png'
import rajanBabu from '../../assets/images/team/RajanBabu.jpeg'
import Lakshmi from '../../assets/images/team/Lakshmi.jpg'
import sridhar2 from '../../assets/images/team/sridhar.jpg'
import { Link } from 'react-router-dom';
import advisor from '../About/Advisors/Main'
// import Menteamdetails from '../../components/Menteamdeatils/First'

function Slidertwo() {
    const team1 = [
        {
            imgSrc: gowthaman,
            title: "A Gowthaman",
            designation: "Managing Director​",
            link:"/team-details"
            
        },
        {
            imgSrc: sridhar,
            title: "C.Sridhar",
            designation: "Director​",
            link:"/team-details"
            
        },
        {
            imgSrc: Lakshmi,
            title: "Lakshmi T",
            designation: "Director​",
            link:"/team-details"

        },
        {
            imgSrc: rajanBabu,
            title: "Rajan Babu",
            designation: "Independent Director​",
            link:"/team-details"

        },
        {
            imgSrc: sridhar2,
            title: "R Sridhar ",
            designation: "Advisor​",
            link:"/advisors"

        }
    ];

    return (
        <>
           
            <div className="client-carousel pt-50 pb-50">
                <div className="container">
                <div className='col-lg-5' >
            <div className="block-title text-left">
            <p>Advisors</p>
                <h2>Meet our Team</h2>
            </div>
        </div>
                    <Swiper
                        modules={[Autoplay]}
                        spaceBetween={40} 
                        slidesPerView={1} 
                        loop={true}
                        autoplay={{ 
                            delay: 1500, 
                            disableOnInteraction: false 
                        }}
                        breakpoints={{
                            "376": {
                                "spaceBetween": 50,
                                "slidesPerView": 2
                            },
                            "576": {
                                "spaceBetween": 50,
                                "slidesPerView": 3
                            },
                            "768": {
                                "spaceBetween": 30,
                                "slidesPerView": 3
                            },
                            "992": {
                                "spaceBetween": 30,
                                "slidesPerView": 3
                            },
                            "1200": {
                                "spaceBetween": 30,
                                "slidesPerView": 4
                            }
                        }}
                    >
                        {team1.map((item, index) => (
                            <SwiperSlide key={index}>
                                <div className={styles.swiperSlide}>
                                    <Link to={item.link}>
                                    <img src={item.imgSrc} alt={item.title} />
                                    </Link>
                                    <div className={styles.title}>
                                        <h2>{item.title}</h2>
                                    </div>
                                    <div className={styles.designation}>
                                        <h4>{item.designation}</h4>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>
        </>
    );
}

export default Slidertwo;
